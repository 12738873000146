<template>
  <div>
    <b-card class="w-100 p-1">
      <!-- Media -->
      <b-row>
        <h4 class="mb-1 p-1">Informações do usuário</h4>
      </b-row>
      <b-media class="mb-2">
        <template #aside>
          <div :class="[!loading ? 'hoverzoom' : '']" v-b-hover="handleHover">
            <img
              v-if="loading == false && src"
              :src="src"
              alt="W3Schools.com"
              style="width: 90px; height: auto; border-radius: 50%"
            />
            <b-avatar
              ref="previewEl"
              :text="avatarText(`${user.username}`)"
              :variant="src ? 'white' : 'light-primary'"
              size="90"
            />
            <!-- <input ref="refInputEl" type="file" class="d-none" @input="addImage" /> -->
            <!-- <div class="retina">
              <feather-icon @click="$refs.refInputEl.click()" v-if="hover && !src" icon="EditIcon" size="20" />
            </div>
            <div class="retina" v-if="loading == false && src">
              <feather-icon @click="removeImage" v-if="hover" class="icon-color" icon="XIcon" size="29" />
            </div> -->
          </div>
          <div v-if="loading == true" style="width: 90px" class="justify-content-center d-flex">
            <b-spinner variant="primary" v-if="loading == true" />
          </div>
        </template>
        <h4>
          {{ user.username  }}
        </h4>
        <span>
         {{user.email }}
        </span>
        <hr>
        <h4>
          Niveis de acesso
        </h4>
        <span>
          {{ user.accesses[0].is_admin ? 'Administrador': '' }} <br>
          {{ user.accesses[0].is_manager ? 'Gerente': '' }} <br>
          {{ user.accesses[0].is_transaction_manager ? 'Gestor Financeiro': '' }} 

        </span>
        <!-- <feather-icon @click="modalChangeEmail = true" icon="EditIcon" size="15" /> -->
      </b-media>
      <b-form>
        <b-row>
          <b-col cols="6" md="6">
            <div class="cursor-pointer vc-text-primary vc-text-14 mt-1" @click="modalChangePassword = true">Alterar senha</div>
          </b-col>
        </b-row>
      </b-form>
      <!-- <b-modal v-model="modalChangeEmail" hide-footer modal-class="modal-primary" centered title="Alterar email">
        <div>
          <b-card-text>
            <validation-observer ref="validationEmail">
              Informe o novo email:
              <b-form-group class="mt-1" label-for="password">
                <validation-provider
                  #default="{ errors }"
                  :custom-messages="{
                    required: 'Campo obrigatório'
                  }"
                  rules="required"
                >
                  <b-form-input v-model="modelUser.people.email" placeholder="Digite aqui..." />
                  <span class="text-danger">{{ errors[0] }}</span>
                </validation-provider>
              </b-form-group>
            </validation-observer>
          </b-card-text>

          <div class="w-100 mt-3 d-flex justify-content-end align-items-center">
            <b-button @click="(modalChangeEmail = false), (modelUser.people.email = '')" variant="danger"
              ><div class="vc-text-button">fechar</div></b-button
            >
            <b-button @click="updateProfile" variant="success" class="ml-1"
              ><div class="vc-text-button">Confimar</div></b-button
            >
          </div>
        </div>
      </b-modal> -->
      <b-modal v-model="modalChangePassword" hide-footer modal-class="modal-primary" centered title="Alterar senha">
        <div>
          <b-card-text>
            Digite sua senha atual:
            <b-input-group class="input-group-merge">
              <b-form-input
                id="login-password"
                v-model="currentPassword"
                class="form-control-merge"
                :type="passwordFieldType"
                name="login-password"
                placeholder="············"
                autocomplete="current-password"
              />
              <b-input-group-append is-text>
                <feather-icon class="cursor-pointer" :icon="passwordToggleIcon" @click="togglePasswordVisibility" />
              </b-input-group-append>
            </b-input-group>
          </b-card-text>
          <b-card-text>
            Nova senha:
            <b-input-group class="input-group-merge">
              <b-form-input
                id="login-password"
                v-model="newPassword"
                class="form-control-merge"
                :type="passwordFieldType"
                name="login-password"
                placeholder="············"
                autocomplete="current-password"
              />
              <b-input-group-append is-text>
                <feather-icon class="cursor-pointer" :icon="passwordToggleIcon" @click="togglePasswordVisibility" />
              </b-input-group-append>
            </b-input-group>
          </b-card-text>
          <b-card-text>
            Confirme sua senha:
            <b-input-group class="input-group-merge">
              <b-form-input
                id="login-password"
                v-model="confirmPassword"
                class="form-control-merge"
                :type="passwordFieldType"
                name="login-password"
                placeholder="············"
                autocomplete="current-password"
              />
              <b-input-group-append is-text>
                <feather-icon class="cursor-pointer" :icon="passwordToggleIcon" @click="togglePasswordVisibility" />
              </b-input-group-append>
            </b-input-group>
          </b-card-text>
          <span v-show="passwordDivergent" class="alertDivergent"> Senhas divergentes...</span>
          <div class="w-100 mt-3 d-flex justify-content-end align-items-center">
            <b-button @click="modalChangePassword = false" variant="danger"><div class="vc-text-button">Fechar</div></b-button>
            <b-button @click="verificationCurrentPassword" variant="success" class="ml-1"
              ><div class="vc-text-button">Confirmar</div></b-button
            >
          </div>
        </div>

        <vx-alert v-if="showAlert" class="mt-1" color="danger" text="Senha incorreta." />
      </b-modal>
    </b-card>
  </div>
</template>

<script>
import {
  BButton,
  BSpinner,
  BMedia,
  BAvatar,
  BModal,
  BRow,
  BCol,
  BFormInput,
  BForm,
  BCard,
  BInputGroupAppend,
  BInputGroup,
  VBHover,
  BCardText
} from 'bootstrap-vue';
import { avatarText } from '@core/utils/filter';
import { togglePasswordVisibility } from '@core/mixins/ui/forms';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
//import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { required } from '@validations';

// import AWS from 'aws-sdk';
import Vue from 'vue';

Vue.directive('b-hover', VBHover);

export default {
  components: {
    BButton,
    BSpinner,
    BMedia,
    BAvatar,
    BModal,
    BRow,
    BCol,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BForm,
    BCard,
    BCardText,
    //ValidationProvider,
    //ValidationObserver
  },
  mixins: [togglePasswordVisibility],
  data: () => ({
    avatarText,
    required,
    hover: false,
    src: null,
    base64: null,
    selectedFile: null,
    currentPath: null,
    currentPassword: '',
    newPassword: '',
    confirmPassword: '',
    modalChangePassword: false,
    passwordDivergent: false,
    modalChangeEmail: false,
    verificationPassword: false,
    showAlert: false,
    loading: false,
  }),
  watch: {
    confirmPassword(val) {
      if (val != this.newPassword) {
        this.passwordDivergent = true;
      } else {
        this.passwordDivergent = false;
      }
    }
  },
  computed: {
    user() {
      return this.$store.getters.user;
    },
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon';
    }
  },
  mounted() {
    this.init();
    console.log('usuario',this.user)
  },
  methods: {
    init() {
     // this.returnProfileImage();
      // this.modelUser = {
      //   people: {
      //     name: this.user.people.name,
      //     cpf: this.user.people.cpf,
      //     rg: this.user.people.rg,
      //     birth_date: this.user.people.birth_date,
      //     gender: this.user.people.gender
      //   },
      //   company_id: this.currentCompany.id,
      //   company_user_id: this.user.people.company_user_id
      // };
    },
    handleHover(hovered) {
      this.hover = hovered;
    },
    // addImage(event) {
    //   const file = event.target.files[0];
    //   this.setImage(file);
    // },
    // setCurrentUserEmail(resp) {
    //   let tempUser = this.user;
    //   tempUser.people.email = resp;
    //   this.$store.commit('SET_USER', tempUser);
    // },
    verificationCurrentPassword() {
      this.$store
        .dispatch('updatePassword', {
          currentPassword: this.currentPassword,
          newPassword: this.newPassword,
          email: this.email
        })
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Atualização de senha',
              text: 'Senha atualizada com sucesso.',
              icon: 'CheckIcon',
              variant: 'success'
            }
          });
          this.modalChangePassword = false;
          this.currentPassword = '';
          this.newPassword = '';
          this.confirmPassword = '';
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Não foi possível atualizar o email.',
              text: `${error.response.data.message}`,
              icon: 'XIcon',
              variant: 'danger'
            }
          });
          this.loading = false;
        });
    },
    // updateProfile() {
    //   this.$refs.validationEmail.validate().then(success => {
    //     if (success) {
    //       this.loading = true;
    //       // var body = {
    //       //   email: this.modelUser.people.email,
    //       //   currentEmail: this.user.people.email,
    //       // };
    //       if (this.modelUser.people.email != '') {
    //         // this.$store
    //         // .dispatch('updateAuthUser', {
    //         //   user_id: this.user.id,
    //         //   // body: body,
    //         // })
    //         // .then(() => {
    //         this.$store
    //           .dispatch('updateUser', {
    //             user_id: this.user.id,
    //             body: {
    //               ...this.modelUser,
    //               email: this.modelUser.people.email,
    //               currentEmail: this.user.people.email,
    //               company_user_id: this.user.company_user.id,
    //               role: this.user.company_user.role.slug
    //             }
    //           })
    //           .then(() => {
    //             this.$toast({
    //               component: ToastificationContent,
    //               props: {
    //                 title: 'Email atualizado com sucesso.',
    //                 icon: 'CheckIcon',
    //                 variant: 'success'
    //               }
    //             });
    //             this.setCurrentUserEmail(this.modelUser.people.email);
    //             this.modelUser.people.email = '';
    //             this.modalChangeEmail = false;
    //             this.loading = false;
    //           })
    //           .catch(error => {
    //             this.$toast({
    //               component: ToastificationContent,
    //               props: {
    //                 title: 'Não foi possível atualizar o email.',
    //                 text: `${error}`,
    //                 icon: 'XIcon',
    //                 variant: 'danger'
    //               }
    //             });
    //             this.loading = false;
    //           });
    //         // })
    //         // .catch((error) => {
    //         //   this.$toast({
    //         //     component: ToastificationContent,
    //         //     props: {
    //         //       title: 'Não foi possível atualizar o email.',
    //         //       text: `${error}`,
    //         //       icon: 'XIcon',
    //         //       variant: 'danger',
    //         //     },
    //         //   });
    //         //   this.loading = false;
    //         // });
    //       } else {
    //         this.$toast({
    //           component: ToastificationContent,
    //           props: {
    //             title: 'Não foi possível atualizar o email.',

    //             icon: 'XIcon',
    //             variant: 'danger'
    //           }
    //         });
    //       }
    //     }
    //   });
    // },
    // setImage(file) {
    //   var resize_width = 90;
    //   const reader = new FileReader();
    //   if (typeof FileReader === 'function') {
    //     reader.onload = event => {
    //       if (event.target.result.substring(0, 10) == 'data:image') {
    //         this.cropActive = true;
    //         this.src = event.target.result;
    //         let base64 = '';
    //         var img = new Image(); //create a image
    //         img.src = event.target.result; //result is base64-encoded Data URI
    //         img.name = event.target.name; //set name (optional)
    //         img.size = event.target.size; //set size (optional)
    //         img.onload = function (el) {
    //           var elem = document.createElement('canvas'); //create a canvas

    //           //scale the image to 600 (width) and keep aspect ratio
    //           var scaleFactor = resize_width / el.target.width;
    //           elem.width = resize_width;
    //           elem.height = el.target.height * scaleFactor;

    //           //draw in canvas
    //           var ctx = elem.getContext('2d');
    //           ctx.drawImage(el.target, 0, 0, elem.width, elem.height);

    //           //get the base64-encoded Data URI from the resize image
    //           this.base64 = ctx.canvas.toDataURL(el.target, 'image/jpeg', 0);
    //         };
    //         base64 = img.src.replace('data:', '').replace(/^.+,/, '');
    //         const newFile = {
    //           name: file.name,
    //           base: base64,
    //           completeBase64: event.target.result,
    //           typeOfFile: event.target.result.toString().split(';')[0].split(':')[1]
    //         };
    //         this.selectedFile = newFile;
    //         this.saveImageProfile();
    //       } else {
    //         this.$toast({
    //           component: ToastificationContent,
    //           props: {
    //             title: 'o tipo de arquivo precisa ser uma imagem!',
    //             icon: 'XIcon',
    //             variant: 'danger'
    //           }
    //         });
    //       }
    //     };
    //     reader.readAsDataURL(file);
    //   }
    // },
    setCurrentUser(resp) {
      let tempUser = this.user;
      tempUser.people.profile_image = resp.path;
      this.$store.commit('SET_USER', tempUser);
    },
    async saveImageProfile() {
      this.loading = true;
      const imageProfile = {
        imagem: this.selectedFile,
        people_id: this.user.people.id
      };
      await this.$store
        .dispatch('savePictureProfile', imageProfile)
        .then(async resp => {
          await this.setCurrentUser(resp);
          this.loading = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Imagem salva com sucesso!',
              icon: 'CheckIcon',
              variant: 'success'
            }
          });
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Erro ao tentar salvar Imagem!',
              icon: 'XIcon',
              variant: 'danger'
            }
          });
          this.loading = false;
        });
    },
    // removeImage() {
    //   this.src = null;
    //   this.loading = true;
    //   this.$store
    //     .dispatch('removeImageProfile', this.user.people.id)
    //     .then(async () => {
    //       this.src = null;
    //       let resp = { path: null };
    //       await this.setCurrentUser(resp);
    //       this.loading = false;
    //       this.removeModal = false;
    //       this.$toast({
    //         component: ToastificationContent,
    //         props: {
    //           title: 'imagem excluída com sucesso!',
    //           icon: 'CheckIcon',
    //           variant: 'success'
    //         }
    //       });
    //     })
    //     .catch(() => {
    //       this.src = this.user.people.logo_image;
    //       this.$toast({
    //         component: ToastificationContent,
    //         props: {
    //           title: 'Erro ao tentar remover imagem!',
    //           icon: 'XIcon',
    //           variant: 'danger'
    //         }
    //       });
    //       this.loading = false;
    //     });
    // },
    // returnProfileImage(logo) {
    //   if (logo) {
    //     const url = this.returnUrlImage(logo);
    //     this.src = url;
    //   } else {
    //     if (this.user.people.profile_image != null) {
    //       const url = this.returnUrlImage(this.user.people.profile_image);
    //       this.src = url;
    //     }
    //   }
    // },
    // returnUrlImage(path) {
    //   if (path) {
    //     const credentials = {
    //       accessKeyId: process.env.VUE_APP_S3_KEY,
    //       secretAccessKey: process.env.VUE_APP_S3_SECRET
    //     };
    //     AWS.config.update({
    //       credentials: credentials,
    //       region: process.env.VUE_APP_S3_REGION
    //     });
    //     const s3 = new AWS.S3();

    //     const presignedGETURL = s3.getSignedUrl('getObject', {
    //       Bucket: process.env.VUE_APP_S3_BUCKET,
    //       Key: path,
    //       Expires: 300
    //     });
    //     return presignedGETURL;
    //   }
    // },
    disabledAfterToday(date) {
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      return date > today;
    }
  }
};
</script>
<style>
h4 {
  margin-top: 2%;
}
.icon-color {
  color: #f75b5b;
}
.icon-color:hover {
  transform: scale(1.5);
}
.hoverzoom {
  position: relative;
  width: 90px;
  height: 90px;
  overflow: hidden;
}

.hoverzoom .retina {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0;
  background: none repeat scroll 0 0 rgba(158, 158, 158, 0.199);
  border-radius: 50%;
  text-align: end;

  -webkit-transition: all 0.8s cubic-bezier(0.19, 1, 0.22, 1);
  -moz-transition: all 0.8s cubic-bezier(0.19, 1, 0.22, 1);
  -ms-transition: all 0.8s cubic-bezier(0.19, 1, 0.22, 1);
  -o-transition: all 0.8s cubic-bezier(0.19, 1, 0.22, 1);
  transition: all 0.8s cubic-bezier(0.19, 1, 0.22, 1);
}

.hoverzoom:hover .retina {
  opacity: 1;
  box-shadow: inset 0 0 100px 50px rgba(165, 165, 165, 0.178);
  border-radius: 50%;
}
.alertDivergent {
  font-weight: bold;
  color: orange;
  margin-top: 5px;
  
}
.vc-text-primary {
  color: #2e5f96;
}
</style>
